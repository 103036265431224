<template>
  <div>
    <body class="bg-grey-lighter">
      <div class="row" style="margin: 20%">
        <div class="col-md-6">
          <select name="" id="" class="dropdown-wrapper">
            <div class="selected-item">
              <span>Select Item</span>
            </div>
            <div class="dropdown-popover">
              <input  v-model="searchQuery" type="text">
              <div class="options">
              <ul>
                <li>opttion 1</li>
                <li>opttion 2</li>
                <li>opttion 3</li>
                <li>opttion 4</li>
                <li>opttion 5</li>
              </ul>
              </div>
            </div>
          </select>
        </div>
      </div>
      <div class="row" style="display: none">
        <div class="col-md-4">
          <div
            class="container mx-auto p-8"
            style="margin-top: 200px; margin-bottom: 200px"
          >
            <Dropdown
              :options="VechMake"
              v-on:selected="makeValidateSelection"
              v-on:filter="getVehicleMake"
              :disabled="false"
              placeholder="Please select an Make"
              :maxItem="120"
            >
            </Dropdown>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="container mx-auto p-8"
            style="margin-top: 200px; margin-bottom: 200px"
          >
            <Dropdown
              :options="VechModel"
              v-on:selected="modelValidateSelection"
              v-on:filter="getVehicleVariant"
              :disabled="false"
              :maxItem="120"
              placeholder="Please select an Model"
            >
            </Dropdown>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="container mx-auto p-8"
            style="margin-top: 200px; margin-bottom: 200px"
          >
            <b-form-select
              v-model="selectedVehicleVarient"
              :options="VechVariant"
            ></b-form-select>
          </div>
        </div>
      </div>
    </body>
  </div>
</template>

<script>
import Dropdown from "vue-simple-search-dropdown";
import userService from "@/services/user.service";
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      VechMake: [],
      VechModel: [],
      VechVariant: [],
      selected: { name: null, id: null },
    };
  },
  mounted() {
    this.listVehicleMake();
  },
  methods: {
    // List Vehicle Make
    listVehicleMake() {
      userService
        .getVehicleMake()
        .then((response) => {
          this.VechMake = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    makeValidateSelection(selection) {
      this.selected = selection;
      if (selection) {
        console.log(selection.name + " has been selected");
        this.listVehicleModel(selection.name);
      }
    },
    getVehicleMake(keyword) {
      if (keyword) {
        console.log(keyword);
        this.VechModel = [];
      }
    },
    // List Vehicle Model

    listVehicleModel(make) {
      userService
        .getVehicleModelList(make)
        .then((response) => {
          this.VechModel = response.data.Data;
          console.log(this.VechModel);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    modelValidateSelection(selection) {
      this.selected = selection;
      if (this.selected) {
        console.log(selection.name + " has been selected");
      }
    },
    getVehicleVariant(keyword) {
      if (keyword) {
        console.log(keyword);
      }
    },
    //List Vehicle Variant
  },
};
</script>

<style>

</style>
